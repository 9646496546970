import moment from 'moment';
import * as yup from 'yup';

import { ISummary } from 'types/account';
import {
    EDynamicPaymentOptions,
    EPaymentSources,
    EWeekDays,
    IPaymentDetails,
    IPaymentDisplayDetails,
    IPaymentInfoSelections,
    IPaymentRules,
    IPaymentViewDetails,
    TPaymentInformation,
    TValidationDetails,
} from 'types/payments';

import { stripFormatting } from '../format';

export const getAmountValidationDetails = (context: yup.TestContext<yup.AnyObject>): yup.ValidationError | boolean => {
    const { accountDetails, additionalAmount, paymentAmount: currency = '' } = context.parent;
    const { currentBalance, monthlyPaymentAmount, paymentLimit, payOffAmount, realEstateProduct } =
        accountDetails as TValidationDetails;

    const numericCurrency = stripFormatting(currency);
    const numericAdditionalAmount = stripFormatting(additionalAmount);
    if (isNaN(numericCurrency) || isNaN(numericAdditionalAmount)) return false;

    const paymentAmount = numericCurrency + numericAdditionalAmount;
    if (paymentAmount <= 0) return false;

    if (realEstateProduct) {
        const monthlyLimit = monthlyPaymentAmount * 5;

        if (paymentAmount >= monthlyLimit || paymentAmount >= paymentLimit)
            return context.createError({
                message: 'pages.payment-manager.screens.addPayment.oneTimePayment.invalidAmountLimitReached',
            });
    }

    if (paymentAmount >= currentBalance)
        return context.createError({
            message: 'pages.payment-manager.screens.addPayment.oneTimePayment.invalidAmountLimitReached',
        });
    if (paymentAmount >= payOffAmount)
        return context.createError({
            message: 'pages.payment-manager.screens.addPayment.oneTimePayment.payOffLimitReached',
        });

    return true;
};

export const shouldDisableDate = (date: Date): boolean =>
    date.getDay() === EWeekDays.SUNDAY || date.getDay() === EWeekDays.SATURDAY;

export const getViewDetailsByPaymentMethod = (
    isRecurring: boolean,
    paymentSource: string,
    summary: ISummary,
    paymentInfo?: IPaymentInfoSelections
): IPaymentViewDetails => {
    const paymentMethod = paymentInfo?.paymentSources.find((item) => item.key === paymentSource);
    const firstRadio = paymentInfo?.paymentSources[0].key || '';
    const viewDetails = {} as IPaymentViewDetails;

    if (isRecurring) {
        viewDetails.showOther = true;
        if (!paymentMethod) {
            viewDetails.fieldName = EDynamicPaymentOptions.PAYMENT_SOURCE;
            viewDetails.fieldValue = firstRadio;
        } else {
            viewDetails.fieldName = EDynamicPaymentOptions.PAYMENT_AMOUNT;
            viewDetails.fieldValue = paymentMethod.value;
        }
    } else {
        const isOther = !!(paymentSource === EPaymentSources.OTHER);
        viewDetails.showOther = isOther;

        if (!isOther) {
            if (!paymentMethod) {
                viewDetails.fieldName = EDynamicPaymentOptions.PAYMENT_SOURCE;
                viewDetails.fieldValue = firstRadio;
            } else {
                const value = summary[paymentSource as keyof ISummary];
                viewDetails.fieldName = EDynamicPaymentOptions.PAYMENT_AMOUNT;
                viewDetails.fieldValue = value;
            }
        }
    }

    return viewDetails;
};

export const getTotalPaymentAmount = (formData: TPaymentInformation): string => {
    const { additionalAmount = '', paymentAmount = '' } = formData;
    const totalPaymentAmount = stripFormatting(paymentAmount) + stripFormatting(additionalAmount);

    return totalPaymentAmount.toString();
};

export const getPaymentRules = (
    isEditing: boolean,
    isRecurring: boolean,
    summary: ISummary,
    payments: IPaymentDetails[]
): IPaymentRules => {
    const { autoPayIndicator, isPaymentAllowed, nextPaymentDate, payOffAttemptFlag } = summary;

    const paymentAllowed = !isPaymentAllowed || payOffAttemptFlag ? false : true;
    const rules: IPaymentRules = {
        hasRecurringPayments: false,
        isOTPBlocked: false,
        isPaymentAllowed: paymentAllowed,
        isRecurringBlocked: false,
    };

    if (!isEditing) {
        if (isRecurring) {
            const today = moment.utc();
            const nextPayment = moment.utc(nextPaymentDate);
            const diffHours = nextPayment.diff(today, 'hours');
            const recurringPayments = payments.filter((payment) => payment.paymentIsRecurring);
            const isNextPaymentDateValid =
                today.isoWeekday() > EWeekDays.WEDNESDAY && nextPayment.isoWeekday() > EWeekDays.FRIDAY;

            rules.isRecurringBlocked = !!(
                diffHours < 24 ||
                recurringPayments.length ||
                autoPayIndicator ||
                (isNextPaymentDateValid && diffHours < 72)
            );
            rules.hasRecurringPayments = !!recurringPayments.length;
        } else {
            const singlePayments = payments.filter((payment) => !payment.paymentIsRecurring);

            rules.isOTPBlocked = singlePayments.length >= 4;
        }
    }

    return rules;
};

export const getDisplayDetails = (isRecurring: boolean, rules: IPaymentRules): IPaymentDisplayDetails => {
    const { hasRecurringPayments, isOTPBlocked, isPaymentAllowed, isRecurringBlocked } = rules;
    const displayDetails: IPaymentDisplayDetails = {
        errorMessage: '',
        showForm: true,
    };

    if (!isRecurring && isOTPBlocked) {
        displayDetails.showForm = false;
        displayDetails.errorMessage = 'pages.payment-manager.screens.addPayment.oneTimePayment.paymentsBlocked';
    } else {
        if (!isPaymentAllowed || isRecurringBlocked) {
            const message = hasRecurringPayments ? 'paymentsBlocked' : 'errorBodyGeneric';

            displayDetails.showForm = false;
            displayDetails.errorMessage = `pages.payment-manager.screens.addPayment.recurringPayment.${message}`;
        }
    }

    return displayDetails;
};

export const checkCanShowControls = (
    isOTPBlocked: boolean,
    isPaymentAllowed: boolean,
    isRecurringBlocked: boolean
): boolean => isPaymentAllowed && !isRecurringBlocked && !isOTPBlocked;

export const isPaymentAllowedInLS = (): boolean => {
    const currentTime = moment.tz('America/New_York');
    const startTime = moment(currentTime).hours(1).startOf('hour');
    const endTime = moment(currentTime).hours(23).startOf('hour');

    return moment(currentTime).isBetween(startTime, endTime);
};
