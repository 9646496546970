import React from 'react';
import { Provider } from 'react-redux';
import { Loading } from '@fairstone/ui/core/components';
import FeatureFlagsProvider from '@fairstone/ui/core/providers/FeatureFlags/FeatureFlagsProvider';
import { LocaleContextProvider } from '@fairstone/ui/core/providers/Intl';
import { Intl as IntlProvider } from '@fairstone/ui/core/providers/Intl';
import { QueryClientProvider } from '@tanstack/react-query';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import { SessionManager } from 'components/SessionManager';
import { REACT_APP_FEATURE_PROVIDER } from 'config/constants';
import translations, { locales } from 'locales';
import { createApiService, createOAMApiService, createRoamApiService } from 'services/api';
import { queryClient } from 'services/client';
import { createDocportalApiService } from 'services/onfido';
import { store } from 'store/redux/store';

import ThemeProvider from './Theme';

export interface IProvider {
    children: React.ReactNode;
}

export type RootState = ReturnType<typeof store.getState>;

const persistor = persistStore(store);
createApiService(store);
createRoamApiService(store);
createDocportalApiService(store);
createOAMApiService(store);

export default ({ children }: IProvider): React.ReactElement => (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <QueryClientProvider client={queryClient}>
                <ThemeProvider>
                    <LocaleContextProvider>
                        <FeatureFlagsProvider configs={REACT_APP_FEATURE_PROVIDER} loadingComponent={<Loading />}>
                            <IntlProvider locales={locales} translations={translations}>
                                <SessionManager>{children}</SessionManager>
                            </IntlProvider>
                        </FeatureFlagsProvider>
                    </LocaleContextProvider>
                </ThemeProvider>
            </QueryClientProvider>
        </PersistGate>
    </Provider>
);
